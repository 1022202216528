( function ( $, window ) {
	$( function () {
		var path;

		if ( 'rb' in window ) {
			path = window.rb.iconSpritePath;
		} else {
			path = rb.iconSpritePath;
		}

		( function ( url ) {
			var id = 'svg-sprite';
			var className = 'u-visually-hidden';
			var container;
			var xhr = new XMLHttpRequest();
			var body = document.body;

			if ( 'withCredentials' in xhr ) {
				xhr.withCredentials;
				xhr.open( 'GET', url, true );
			} else if ( typeof XDomainRequest != 'undefined' ) {
				xhr = new XDomainRequest();
				xhr.open( 'GET', url );
			} else {
				body.className += ' no-svg-sprite';
				return;
			}

			xhr.onload = function () {
				container = document.createElement( 'div' );
				container.id = id;
				container.className = className;
				container.innerHTML = xhr.responseText;

				body.insertBefore( container, body.childNodes[0] );
			};

			xhr.onerror = function () {
				body.className += ' no-svg-sprite';
			};

			setTimeout( function () {
				xhr.send();
			}, 0 );
		} )( `${path}/images/icon-sprite.svg` );

	} );
} )( jQuery, window );
